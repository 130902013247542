<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="shipping"></submenu-account>

    <div class="submenu-content">
      <div class="pt-4">
          <ul class="nav nav-tabs" id="tab" >
            <li class="nav-link active"><router-link :to="{ name: 'ShipmentTracking'}">Invoice</router-link></li>
            <li class="nav-link"><router-link :to="{ name: 'WebOrder'}">Web  PO</router-link></li>
        </ul>
      </div>

      <div class="desc">
        <p>Invoices in the past 90 days:</p>
      </div>
     

      <div v-if="orders">
        <spinner v-if="loading" />
        <pagination2
          v-if="!loading"
          :currentPage="currentPage"
          :pageCount="pageCount"
        />
        <div class="tb">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Order Date</th>
                  <th scope="col">Ship Date</th>
                  <th scope="col">Invoice #</th>
                  <th scope="col">Web P.O.#</th>
                  <th scope="col">PO #</th>
                  <th scope="col">Ship Via</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Branch</th>
                  <th scope="col">Tracking #</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(order,key) in orders" :key="key">
                  <tr>
                    <th>{{order.orddate}}</th>
                    <td>{{order.shipdate}}</td>
                    <td> 
                      <router-link
                        :to="{name: 'OrderDetails',params: { invno: order.invno}}"
                        >{{ order.invno }}
                    </router-link>
                    <!-- {{order.invno}} -->
                    </td>
                    <td>{{order.web_so}}</td>
                    <td>{{order.pono}}</td>
                    <td>{{order.shipvia}}</td>
                    <td>{{formatPrice(order.amount)}}</td>
                    <td>{{order.branch}}</td>
                    <td><a :href="order.tracking_url" target="blank">{{order.tracking_no}}</a></td>
                  </tr>  
                </template>                       
              </tbody>
            </table>
        </div>
      </div>
      <div v-else>
        <p>no orders</p>

      </div>
    </div>  

  </div>
</template>

<script>
import submenuAccount from '../../components/nav/submenuAccount.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import Spinner from "../../components/Spinner.vue";
import Pagination2 from "../../components/Pagination2.vue";
import {getAuthAPI } from "../../utils/axios-api";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref } from 'vue'
import { formatPrice } from '../../utils/common';

export default {
  name: 'ShipmentTracking',
  components: { submenuAccount, Pagination2, Spinner, BreadcrumbPage },

  setup(){
    const loading = ref(true);
    const orders = ref([])
    const pageCount = ref(null)
    const error = ref("")
    const currentPage = ref(1)
    const itemsPerPage = ref(20)
    const offset = ref(0)
    const urlParams = ref("")
    const tracking_url = ref("")
    
    const route = useRoute()

    //get custno
    let custno = ""
    const store = useStore()
    var user = store.getters.user
    if (!!user) custno = user["custno"]

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Order Status", link: "ShipmentTracking"})
    breadcrumb_links.value.push({text: "Invoice", link: "ShipmentTracking"})

    if (typeof route.query.page === "undefined") {
      currentPage.value = 1;
    } 
    else {
      currentPage.value = parseInt(route.query.page);
      if (currentPage.value < 1) {
        currentPage.value = 1;
      }
    }
  
    offset.value = (currentPage.value - 1) * itemsPerPage.value;
    urlParams.value = urlParams.value  +"?limit="+itemsPerPage.value + "&offset=" + offset.value

    let url = "/order/listing/" + custno + "/" + urlParams.value
    getAuthAPI
    .get(url)
    .then((response) => {
      let ords = response.data.results;
      console.log(ords)
      let totalCount = response.data.count
      pageCount.value = Math.ceil(response.data.count / itemsPerPage.value)
      // console.log(pageCount.value,"qqq")
        for (let ord in ords) {
          orders.value.push(
            {"orddate":ords[ord].orddate,
            "shipdate":ords[ord].shipdate,
            "pono":ords[ord].pono,
            "invno":ords[ord].invno,
            "web_so":ords[ord].web_so,
            "shipvia":ords[ord].shipvia,
            "amount":ords[ord].invoice_total,
            "branch":ords[ord].branch,
            "tracking_no":ords[ord].tracking_no,
            "tracking_url":ords[ord].tracking_url
            }
          )         
        } 
        loading.value = false;
    })
    .catch(err => {
      loading.value = false;
       error.value = err.message
       console.log(error.value)
    })

    return {
        loading,
        orders,
        pageCount,
        currentPage,
        breadcrumb_links,
        formatPrice
      }

  }  

}
</script>


<style scoped>

#tab li.active{
  border-bottom: 8px solid #4d88e8;
  color:#444;
  font-weight: bold;
}
.desc{
  padding: 20px 10px;
}

</style>