<template>
<div class="pagination_sec">
<!-- <hr> -->

    <div class="container clearfix">
        
        <div class="float-end">

        <div id="paginator" class="btn-group">     
            <a v-if="currentPage==1" class="btn btn-group-sm btn-outline-secondary disabled">&lt Prev</a>
            <a v-else :href="'/account/order-status/?page=' + prevPage" class="btn btn-group-sm btn-outline-secondary">&lt Prev</a>
            
            <router-link :to="'/account/order-status/?page=' + p" class="btn btn-group-sm btn-outline-secondary" v-for="p in p_start" :class="p==currentPage? 'highlight disabled': ''">{{p}}</router-link>
            <a class="btn btn-group-sm btn-outline-secondary disabled" v-if="p_middle.length > 0">...</a>
            <router-link :to="'/account/order-status/?page=' + p" class="btn btn-group-sm btn-outline-secondary" v-for="p in p_middle" :class="p==currentPage? 'highlight disabled': ''">{{p}}</router-link>
            <a class="btn btn-group-sm btn-outline-secondary disabled" v-if="p_end.length > 0">...</a>
            <router-link :to="'/account/order-status/?page=' + p" class="btn btn-group-sm btn-outline-secondary" v-for="p in p_end" :class="p==currentPage? 'highlight disabled': ''">{{p}}</router-link>
            
            <a v-if="currentPage<pageCount" :href="'/account/order-status/?page=' + nextPage" class="btn btn-group-sm btn-outline-secondary">Next &gt</a>
            <a v-else class="btn btn-group-sm btn-outline-secondary disabled">Next &gt</a>
        </div>

        <div id="pageNumber" class="float-end ps-2">
            <div class="input-group mb-3">
                <span class="input-group-text">Page:</span>
                <input type="text" v-model="pageNinput" id="page_input" class="form-control" aria-describedby="button-addon2">
                <a @click="handleSubmit" class="btn btn-outline-secondary" type="button" id="button-addon2" :href="'/account/order-status/?page=' + pageN">Go</a>
            </div>
            
        </div>                  
        </div>
        
    </div>

</div>



</template>

<script>
import { ref } from 'vue'

export default {
  props: [ 'currentPage', 'pageCount'],
  setup(props) {
      const message = ref('')
      const pageCount = ref(props.pageCount)
      const currentPage = ref(props.currentPage)
      const prevPage = ref(null)
      const nextPage = ref(null)
      const pageN = ref(null)
      const pageNinput = ref(null)

        console.log("pagination pageCount", pageCount.value)
        console.log("pagination curretnPage", currentPage.value)


      if (currentPage.value < 1) {
          currentPage.value = 1
      }
      else if (currentPage.value > pageCount.value) {
          currentPage.value = pageCount.value
      }
      if (currentPage.value == 1){
          prevPage.value = 1
      }
      else {
          prevPage.value = currentPage.value - 1
      }
      if (currentPage.value == pageCount.value) {
          nextPage.value = pageCount.value
      }
      else {
          nextPage.value = currentPage.value + 1
      }
    //   console.log("sorttt"+ sortBy.value)

    
      var i
      const p_start = ref([])
      const p_middle = ref([])
      const p_end = ref([])
      if (pageCount.value <= 10) {
        //p_start
        for (i = 1; i <= pageCount.value; i++){
            p_start.value.push(i)
        }
      }
      else {
        if (currentPage.value <= 5) {
            //p_start
            for (i = 1; i <= 6; i++){
                p_start.value.push(i)
            }
            //p_middle
            p_middle.value = []
            //p_end
            p_end.value = [pageCount.value]
        }
        else if (currentPage.value < pageCount.value - 4) {
            
            //p_start
            p_start.value = [1]
            //p_middle
            for (i = currentPage.value - 1; i <= currentPage.value + 1; i++){
                p_middle.value.push(i)
                console.log(i)
            }
            //p_end
            p_end.value = [pageCount.value]
            
        }
        else {
            //p_start
            p_start.value = [1]
            //p_middle
            p_middle.value = []
            //p_end
            for (i = pageCount.value - 5; i <= pageCount.value ; i++){
                p_end.value.push(i)
            }
        }
      }
      const handleSubmit = () => {
          pageN.value = pageNinput.value        
      }

    


      
      return { p_start, 
               p_middle, 
               p_end, 
               currentPage, 
               prevPage, 
               nextPage, 
               pageCount,
               pageN,
               pageNinput,
               handleSubmit,
            }
  }
}
</script>

<style scoped>
.pagination_sec {
    height:60px;
}
#paginator .highlight{
    font-weight: bold;
    background-color: #ccc;
}
#page_input {width:50px;}
</style>